$primary: #d5306e;/* MAIN COLOR */
$secondary: #1485CC; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

@import url('https://fonts.googleapis.com/css?family=Bevan|Quicksand:500');

html, body {
    height: 100%;
}

body {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

h1,h2,h3 {
   font-family: 'Bevan', cursive; 
   font-size: 2em;
   @media (max-width: 991px) {
    font-size: 1.5em;
   }
}

.flash {
    display: none;
}


p {
    font-size: 1.3em;
    font-family: 'Quicksand', sans-serif;
    @media (max-width: 1199px) {
        font-size: 1.1em;
    }
}

q,
blockquote {
    quotes: "“" "”";
}

.navbarFixed {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
}


/* Change autocomplete styles in WebKit */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid #0065dd;
    -webkit-text-fill-color: #0065dd;
    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
}

.navbar-default {
    background: white;
}

nav.navbar {
    z-index: 1000;
    border: none;
    border-radius: $border-radius;
    .navbar-nav {
        >li>a {
            text-align: center;
            margin-top: 22px;
            display: flex;
            align-items: center;
            color: $blk;
            @media (max-width: 767px) {
                margin-top: 0;
                padding: 3px 0px;
                display: inline-block;
            }
            &:focus,
            &:active {
                background: transparent;
                color: $blk;
                outline: 0;
            }
            &:hover {
                background: $primary;
                color: $wht;
                @media (max-width: 1024px) {
                    background: transparent;
                    color: $blk;
                    outline: 0;
                }
            }
        }
    }
}

.navbar-right {
    margin-top: 0px;
}

.navbar-toggle {
    margin: 20px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
}


/** LOGIN FORM **/

@mixin btn {
    border: none;
    border-radius: $border-radius;
    background: darken($primary, 20%);
    color: $wht;
    padding: 0.5em 2em;
    font-size: 1.25em;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    margin: 2em 0em;
    transition: 0.7s ease all;
    box-shadow: none;
    &:hover {
        background: darken($primary, 30%);
        color: $wht;
        text-decoration: none;
        @media (max-width: 1024px) {
            background: darken($primary, 20%);
            color: $wht;
        }
    }
    &:focus {
        background: darken($primary, 30%);
        color: $wht;
    }
}

.btn-default {
    @include btn;
}

.modal-dialog {
    max-width: 300px;
    text-align: center;
    margin: 6em auto;
    .close {
        display: none;
    }
    .modal-content {
        color: $primary;
        h2 {
            text-align: center;
        }
    }
    input {
        border: 1px solid #777;
        text-align: center;
    }
    input#username,
    input#password {
        @media (max-width: 1024px) {
            font-size: 16px;
        }
    }
    button {
        background: transparent;
        color: $wht;
        display: block;
        border: none;
        padding: 1em 2em;
        margin: 0 auto;
        font-size: 18px;
        &:hover {
            background: transparent;
            color: #fff;
            box-shadow: none;
            text-shadow: 0px 0px 3px $blk;
            border: none;
        }
    }
    input[type="submit"] {
        @include btn;
        display: block;
        width: 75%;
        font-size: 18px;
        margin: 0 auto;
    }
}

.modal-header,
.modal-footer {
    background: $primary;
    color: $wht;
}

.modal-open {
    overflow: auto;
    padding-right: 0px !important;
}

input#username {
    margin-bottom: 20px;
}


/** END LOGIN FORM **/

footer {
    padding: 50px 0px 20px;
    background: $primary;
    color: $footerLinks;
    font-size: 15px;
    a {
        color: $footerLinks;
        white-space: nowrap;
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
        &:hover {
            color: lighten($footerLinks, 10%);
            outline: 0 !important;
            text-decoration: none;
        }
        &:focus {
            color: $wht;
            outline: 0 !important;
            text-decoration: none;
        }
    }
    p {
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
    }
}

input#username,
input#password {
    width: 100%;
    @media (max-width: 1024px) {
        font-size: 16px;
    }
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

a,
a:hover,
a:focus,
a:active {
    outline: 0 !important;
}

.logo {
    max-width: 250px;
    padding: 0.5em;
}

@media(max-width: 767px) {
    .logo {
        max-width: 175px;
    }
    .navbar-header a {
        float: left;
    }
}

.top-pad {
    padding: 3em;
    background: white;

    @media (max-width: 990px) {
        padding: 1em;
    }    
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 1024px) {
        display: block;
        /* may need to be inline-block */
    }
}

.terms,
.privacy {
    p {
        font-size: 15px;
    }
    
    & ol {
        li {
            font-size: 1em;
        }
        & ol li {
            font-size: 0.75em;
        }
    }
}

.solidBg {
    background: $primary;
    color: white;
    .colBg {
        background: white;
    }
    .introTxt {
        padding: 0 40px;
        @media (max-width: 991px) {
            padding: 30px 15px;
        }
    }
    .noPad {
        padding: 0;
    }
}

.tble {
    display: table;
    @media (max-width: 991px) {
        display: block;
    }
    .tble-cell {
        display: table-cell;
        vertical-align: middle;
        float: none;
        @media (max-width: 991px) {
            display: block;
        }
    }
}

.bg {
    background: url(../img/banner.jpg) no-repeat;
    background-size: cover;
    background-position: 50%;
    .solidBgTxt {
        background: rgba($primary, 0.7);
        @media (max-width: 991px) {
            padding: 30px 15px;
            background: rgba($primary, 0.8);
            p {
                padding: 15px 0;
            }
        }
    }
    .form {
        background: rgba($secondary, 0.7);
    }
    h1, p {
        color: white;
    }
    h1 {
        margin: 0;
    }
}

a.btn {
    @include btn;
}


.navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover {
    background: transparent;
}
.bg2 {
    background: url(../img/banner2.jpg) no-repeat;
    background-size: cover;
    padding: 220px 0;
    background-position: 50%;
    @media (max-width: 991px) {
        padding: 150px 0;
    }
    @media (max-width: 767px) {
        padding: 100px 0;
    }
    .txtBox {
        background: rgba($primary, 0.7);
        color: white;
        padding: 40px;
        @media (max-width: 991px) {
            padding: 20px;
        }
        p {
            font-size: 2em;
            @media (max-width: 991px) {
                font-size: 1.5em;
            }
            @media (max-width: 767px) {
                font-size: 1.2em;
            }
        }
    }
}

.bg3 {
    background: url(../img/banner3.jpg) no-repeat;
    background-size: cover;
    padding: 220px 0;
    background-position: 50% 0%;
    @media (max-width: 991px) {
        padding: 150px 0;
    }
    @media (max-width: 767px) {
        padding: 100px 0;
    }
    @media (max-width: 600px) {
        padding: 75px 0;
    }
    .txtBox2 {
        background: rgba($secondary, 0.7);
        padding: 40px;
        color: white;
        @media (max-width: 991px) {
            padding: 20px;
        }
        .h1txt {
            @media (max-width: 991px) {
                font-size: 1.2em;
            }
        }
    }
}